import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "./../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <h1>Страницата не е намерена</h1>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6'>
            <StaticImage
              src="../images/not-found.png"
              alt="Illustration of chef, not found"
              width={700}
              height={700}
              quality={100}
            />
          </div>
          <div className='col-sm-6'>
            <p className="fs-2">
            За съжаление не успяхме да намерим това, което търсите.
              <br/>
              <br />
              <Link className='text-cobalt' to="/">Върнете се в началната страница</Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
